import * as Popper from "@popperjs/core";

window._ = require('lodash');
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import Resumable from 'resumablejs';
window.Resumable = Resumable;

import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js';
Sortable.mount(new AutoScroll());
window.Sortable = Sortable;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

