require('./bootstrap');

const checkall = document.getElementById('checkall');
if ( checkall ) {
    checkall.addEventListener('click', e=>{
        const checkboxes = document.querySelectorAll('table tbody input[type="checkbox"]');
        if ( checkboxes.length === 0 ) return;

        checkboxes.forEach(el=>{
            el.checked = checkall.checked;
        })
    })
}
